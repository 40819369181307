@import 'global.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @include only-mobile {
    padding: 20px;
    padding-bottom: 90px;
  }

}

.title {
  @include heading-h4;
  margin: 0;

  @include desktop {
    @include heading-h2;
  }
}

.subtitle {
  @include paragraph-p4;
}

.btn {
  margin-top: 12px;
}
